/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import SEO from '../../../seo';
import Layout from '../../../shared/Layout';
import useReduxHandler from '../../../../hooks/useReduxHandler';
import RowManager from '../shared/RowManager';
import SectionContainer from '../../sections/SectionContainer';
import withPermission from '../../../ACL/withPermission';
import linkUtils from '../../../utils/linkUtils';
import BackToTop from '../../../shared/BackToTop';
import { getBgColor } from '../shared/ApiManager/util';
import contentParser from '../../../utils/contentParser';
import { getTabSize } from '../../../utils/functionUtils';
import Users from '../../pages/UsersPage';
import { isMobileOnly } from 'react-device-detect';

function PrivateTemplate(props) {
	// eslint-disable-next-line react/destructuring-assignment
	const { pageContext, path, location } = props[0];

	const {
		slug,
		title,
		description,
		keywords,
		sections,
		styles,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
		searchOptions,
	} = pageContext;
	const { isLoggedIn, setActiveItemFromScroll, getActiveItem } =
		useReduxHandler();
	const isLogged = isLoggedIn();
	const color = isLogged && 'dark';

	const [sidebarItems, setSidebarItems] = useState(null);

	useEffect(() => {
		if (location.hash) {
			setTimeout(() => {
				const element = document.getElementById(location.hash.replace('#', ''));
				console.log('🚀 ~ useEffect ~ element found:', element);
				element?.scrollIntoView({ block: 'nearest', behavior: 'auto' });
			}, 1500); // Ajusta el tiempo de espera según sea necesario
		}
	}, [location.hash]);

	function convertToSidebarItems() {
		const sectionsToScroll = [];

		const menuTree = [];
		sections.forEach((section) => {
			const textMenu = section.sidebarTitle || null;
			const linkMenu = section
				? linkUtils.createLink(
						section.sidebarTitle,
						section.name,
						section.title,
						section.id
				  )
				: null;
			const sectionLevel = {
				text: textMenu,
				href: `#${linkMenu}`,
				type: 'internal',
				slug,
				childs: [],
			};
			menuTree.push(sectionLevel);
			let levelZero = null;
			let levelOne = null;
			let levelTwo = null;
			let levelThree = null;
			let levelFour = null;
			let levelFive = null;
			let levelSix = null;
			let levelSeven = null;
			let parentOfSectionToPush = { sidebarTitle: section.sidebarTitle };
			section.sub_sections.forEach((row) => {
				if (row.sidebarTitle) {
					const sidebarLevel = getTabSize(row.SidebarLevel?.name);

					switch (sidebarLevel) {
						case 0:
							if (!sectionLevel) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								sectionLevel.childs.push(currentRow);
								levelZero = currentRow;
							}
							break;
						case 1:
							if (!levelZero) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelZero.childs.push(currentRow);
								levelOne = currentRow;
							}
							break;
						case 2:
							if (!levelOne) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}_${levelOne.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelOne.childs.push(currentRow);
								levelTwo = currentRow;
							}
							break;
						case 3:
							if (!levelTwo) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}_${levelOne.text}_${levelTwo.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelTwo.childs.push(currentRow);
								levelThree = currentRow;
							}
							break;
						case 4:
							if (!levelThree) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}_${levelOne.text}_${levelTwo.text}_${levelThree.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelThree.childs.push(currentRow);
								levelFour = currentRow;
							}
							break;
						case 5:
							if (!levelFour) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}_${levelOne.text}_${levelTwo.text}_${levelThree.text}_${levelFour.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelFour.childs.push(currentRow);
								levelFive = currentRow;
							}
							break;
						case 6:
							if (!levelFive) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}_${levelOne.text}_${levelTwo.text}_${levelThree.text}_${levelFour.text}_${levelFive.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelFive.childs.push(currentRow);
								levelSix = currentRow;
							}
							break;
						case 7:
							if (!levelSix) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}_${levelOne.text}_${levelTwo.text}_${levelThree.text}_${levelFour.text}_${levelFive.text}_${levelSix.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelSix.childs.push(currentRow);
								levelSeven = currentRow;
							}
							break;
						case 8:
							if (!levelSeven) {
								// sendErrorToApi(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
								// console.log(
								// 	`The subsection ${row.name} don't have a subsecton parent with sidebarLevel 0`
								// );
							} else {
								parentOfSectionToPush = {
									sidebarTitle: `${sectionLevel.text}_${levelZero.text}_${levelOne.text}_${levelTwo.text}_${levelThree.text}_${levelFour.text}_${levelFive.text}_${levelSix.text}_${levelSeven.text}`,
								};
								const currentRow = {
									text: row.sidebarTitle || null,
									tabSize: sidebarLevel,
									slug,
									href: `#${linkUtils.createLink(
										row.sidebarTitle,
										row.name,
										row.title,
										row.id,
										parentOfSectionToPush
									)}`,
									type: 'internal',
									childs: [],
								};
								levelSeven.childs.push(currentRow);
							}
							break;

						default: {
							parentOfSectionToPush = { sidebarTitle: section.sidebarTitle };
							const currentRow = {
								text: row.sidebarTitle || null,
								tabSize: sidebarLevel,
								slug,
								href: `#${linkUtils.createLink(
									row.sidebarTitle,
									row.name,
									row.title,
									row.id,
									parentOfSectionToPush
								)}`,
								type: 'internal',
								childs: [],
							};
							sectionLevel.childs.push(currentRow);
							levelZero = currentRow;
							break;
						}
					}
					sectionsToScroll.push({
						id: linkUtils.createLink(
							row.sidebarTitle,
							row.name,
							row.title,
							row.id,
							parentOfSectionToPush
						),
						visible: false,
					});
				} else {
					// sendErrorToApi(`The subsection ${row.name} don't have sidebarTitle`);
					// console.log(`The subsection ${row.name} don't have sidebarTitle`);
				}
			});
		});

		return menuTree;
	}
	if (sidebarItems === null) setSidebarItems(convertToSidebarItems);

	useEffect(() => {
		if (sidebarItems === null) setSidebarItems(convertToSidebarItems);
	}, [sections.length]);
	const [itemsToSpy, setItemsToSpy] = useState([]);
	const changeVisibility = (id, isVisible) => {
		if (typeof window !== 'undefined' && id) {
			if (isVisible) {
				itemsToSpy.push({ id, visible: isVisible });
			} else if (itemsToSpy.some((el) => el.id === id)) {
				setItemsToSpy(
					itemsToSpy.filter((item) => {
						if (item.id === id) {
							return false;
						}
						return true;
					})
				);
			}
			if (itemsToSpy.length > 0 && getActiveItem() !== itemsToSpy[0].id)
				setActiveItemFromScroll(itemsToSpy[0].id);
		}
	};

	if (slug === 'users') {
		return (
			<Layout
				isPrivate
				isLogged={isLogged}
				type="private"
				viewMainSection={false}
				viewSideBar={false}
				classNameMain="bg-white main-partner-portal"
				header={color}
				menu={headerMenus}
				footerTopMenu={footerTopMenus}
				footerBottomMenu={footerBottomMenus}
				sidebarItems={sidebarItems}
				path={path}
				fluid={slug === 'api'}
				slug={slug}
				searchOptions={searchOptions}
			>
				<SEO
					title={title}
					description={description}
					pathname={path}
					cssStyle={styles && contentParser.getOnlyContent(styles)}
					keywords={keywords}
				/>
				<Users />
			</Layout>
		);
	}

	return (
		<>
			<Layout
				isPrivate
				isLogged={isLogged}
				type="private"
				viewMainSection={false}
				viewSideBar={slug !== 'retrieve-token.html' && slug !== 'nda'}
				classNameMain="bg-white main-partner-portal"
				header={color}
				menu={headerMenus}
				footerTopMenu={footerTopMenus}
				footerBottomMenu={footerBottomMenus}
				sidebarItems={sidebarItems}
				path={path}
				fluid={slug === 'api'}
				slug={slug}
				searchOptions={searchOptions}
			>
				<SEO
					title={title}
					description={description}
					pathname={path}
					cssStyle={styles && contentParser.getOnlyContent(styles)}
					keywords={keywords}
				/>
				<div
					id="loadingResources"
					style={{
						width: '100%',
						height: '95vh',
						position: 'fixed',
						top: '15px',
						// background: 'red',
					}}
				/>
				<div
					id="viewportSensor"
					style={{
						width: '100%',
						height: '30px',
						position: 'fixed',
						top: '15px',
					}}
				/>

				<div
					id="itemsSensor"
					style={{
						width: '100%',
						height: '80%',
						position: 'fixed',
						top: '35px',
					}}
				/>
				<h1 className="page-title">{title}</h1>
				{sections &&
					sections.length > 0 &&
					sections.map((section) => {
						const sectionId = linkUtils.createLink(
							section.sidebarTitle,
							section.name,
							section.title,
							section.id
						);
						return (
							<SectionContainer
								key={`${
									section.sidebarTitle &&
									section.sidebarTitle.replace(/\s+/g, '_')
								}-${slug}-${title}`}
								id={`${sectionId}-section`}
								className={`private-section ${slug}-section ${
									section.className || ''
								}`}
								bgColor={getBgColor(section.bgColor)}
							>
								<Container>
									<RowManager
										key={section.id}
										row={section}
										slug={slug}
										isPrivate
										changeVisibility={changeVisibility}
										sectionId={sectionId}
									/>
								</Container>
							</SectionContainer>
						);
					})}
				{!isMobileOnly && <BackToTop showBackToBottom />}
			</Layout>
		</>
	);
}

export default withPermission(PrivateTemplate);
